<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-2">
      <h2 class="text-lg font-medium mr-auto">Beasiswa Mahasiswa</h2>
      <router-link class="btn btn-primary" :to="{ name: 'create-beasiswa' }"
        ><PlusCircleIcon class="mr-1 h-5"></PlusCircleIcon> Buat
        Beasiswa</router-link
      >
      <router-link class="btn btn-warning ml-1" :to="{ name: 'copy-beasiswa' }"
        ><CopyIcon class="mr-1 h-5"></CopyIcon> Lanjut Beasiswa</router-link
      >
      <router-link
        class="btn btn-success ml-1"
        :to="{ name: 'transfer-beasiswa' }"
        ><SendIcon class="mr-1 h-5"></SendIcon> Transfer Beasiswa</router-link
      >
    </div>

    <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
    <AlertFailed v-if="alertFailed" :message="messageAlert" />

    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form
          id="tabulator-html-filter-form"
          class="xl:flex sm:mr-auto"
          @submit.prevent="onFilter"
        >
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="nipd">NPM</option>
              <option value="reg_pd.nm_pd">Nama</option>
              <option value="jenis">Jenis</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Type</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like" selected>like</option>
              <option value="=">=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { tblTabulator } from "@/utils/tabulator";
import AlertSuccess from "../../../components/alerts/AlertSuccess.vue";
import AlertFailed from "../../../components/alerts/AlertFailed.vue";
import router from "@/router";
import { keuangan } from "@/services/models";
import { helper } from "../../../utils/helper";

export default defineComponent({
  components: {
    AlertSuccess,
    AlertFailed,
  },
  setup() {
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const idBeasiswa = ref();
    const alertSuccess = ref();
    const alertFailed = ref();
    const messageAlert = ref();
    const filter = reactive({
      field: "reg_pd.nm_pd",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];

    const editRow = (e, cell) => {
      idBeasiswa.value = cell.getData()?.id;
      alertSuccess.value = false;
      alertFailed.value = false;
      messageAlert.value = "";
      if (cell.getData()?.validasi == 0) {
        router.push({
          name: "edit-beasiswa",
          params: { id: idBeasiswa.value },
        });
      } else {
        alertFailed.value = true;
        messageAlert.value = "Maaf, tidak bisa diubah";
      }
    };

    const deleteRow = (e, cell) => {
      idBeasiswa.value = cell.getData()?.id;
      alertSuccess.value = false;
      alertFailed.value = false;
      messageAlert.value = "";
      if (cell.getData()?.validasi == 0) {
        keuangan.Beasiswa.deleteData(idBeasiswa.value).then((res) => {
          console.log(res);
          onFilter();
        });
      } else {
        alertFailed.value = true;
        messageAlert.value = "Maaf, tidak bisa diubah";
      }
    };

    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        {
          title: "NPM",
          width: 100,
          field: "reg_pd.nipd",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Nama",
          field: "reg_pd.peserta_didik.nm_pd",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "SMT",
          field: "id_smt",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Jenis",
          field: "jenis_biaya.keterangan",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Akun",
          field: "accountan.account",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Keterangan",
          field: "keterangan",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Nominal",
          field: "nominal",
          hozAlign: "right",
          vertAlign: "top",
          print: true,
          download: true,
          formatter(cell) {
            return helper.IDR(cell.getData().nominal);
          },
        },
        {
          title: "Validasi",
          width: 200,
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          formatter(cell) {
            return cell.getData().validasi == 1
              ? '<div class="rounded-full text-center text-xs px-3 py-1 bg-theme-9 text-white font-small">Telah Digunakan</div>'
              : '<div class="rounded-full text-center text-xs px-3 py-1 bg-theme-6 text-white font-small">Belum Digunakan</div>';
          },
        },
        {
          title: "#",
          field: "id",
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let routeData = router.resolve({
              name: "edit-beasiswa",
              params: { id: cell.getData().id },
            });
            //if (cell.getData().validasi == 0) {
            return `
                <div>
                  <a class="flex space-x-1 items-center btn btn-sm btn-warning" href="${routeData.href}">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14px" fill="#000"><path d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg>
                      <span class="ml-1">Edit</span>
                  </a>
                </div>`;
            //}
          },
          cellClick: editRow,
        },
        {
          title: "##",
          field: "id",
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            if (cell.getData().validasi == 0) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center tooltip text-theme-6" title="Hapus" href="javascript:;">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6" fill="#ef4444"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM160 128C151.2 128 144 135.2 144 144C144 152.8 151.2 160 160 160H352C360.8 160 368 152.8 368 144C368 135.2 360.8 128 352 128H310.6L299.3 116.7C296.3 113.7 292.2 112 288 112H224C219.8 112 215.7 113.7 212.7 116.7L201.4 128H160zM160 192L173.6 354.7C174.9 371.2 188.8 384 205.4 384H306.6C323.2 384 337.1 371.2 338.4 354.7L352 192H160z"/></svg>
                </a>
              </div>`);
              return a[0];
            }
          },
          cellClick: deleteRow,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "beasiswa",
        columns
      );
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "nipd";
      filter.type = "=";
      filter.value = "";
      onFilter();
    };
    onMounted(() => {
      initTabulator();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      accessToken,
      idBeasiswa,
      alertSuccess,
      alertFailed,
      messageAlert,
    };
  },
});
</script>
